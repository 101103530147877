import React, { useEffect } from "react";

import Account from "@shared/drawer/account";
import { Container } from "@util/standard";
import { assets } from "@util/constants";
import { Script, navigate } from "gatsby";
import styled from "styled-components";
import { useStore } from "@state/store";
import { getCookieValue } from "@util/helper";
import { logoutUser } from "@auth/getFirebase";

const StyledPage = styled.div`
  .logo {
    height: 100px;
    width: 200px;
    object-fit: contain;
    margin: auto;
  }
`;

export default function IndexPage() {
  const { user } = useStore();
  const loginCookieExists = getCookieValue("101LaunchpadLogin");

  const handlePageLoad = () => {
    if (user && loginCookieExists == false) {
      console.log("logout");
      return setTimeout(() => {
        logoutUser();
      }, 1000);
    }

    if (user?.uid && user?.isGroupAdmin) {
      return navigate("/houston");
    }
    if (user?.uid && !user?.isGroupAdmin) {
      return navigate("/dashboard");
    }

    return;
  };

  useEffect(() => {
    handlePageLoad();
  }, [user]);

  return (
    <StyledPage>
      <Script
        id="sib"
        dangerouslySetInnerHTML={{
          __html: ` (function(d, w, c) {
        w.SibConversationsID = '63be052fd44be91a1b24e842';
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        var s = d.createElement('script');
        s.async = true;
        s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'SibConversations');`,
        }}
      />
      <Container
        width="70%"
        height="fit-content"
        margin="100px auto"
        flexDirection="column"
        isMobileColumn
      >
        <img className="logo" src={assets.dashboardLogo} />

        <Account />
      </Container>
    </StyledPage>
  );
}
